<template>
  <div class="zt-page-content">
    <div class="page-header">家具工厂</div>
    <div class="page-body">
      <!-- 头部筛选条件 -->
      <el-card class="box-card">
        <div class="search-event-item">
          <div class="text item">
            筛选:
            <el-select
              @change="companyTypeChanged"
              v-model="selectConfig.searchAddress"
              placeholder="所在地区"
              size="mini"
              filterable
            >
              <el-option
                v-for="item in selectConfig.searchAddressList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-select
            @change="companyTypeChanged"
            v-model="selectConfig.searchMember"
            placeholder="员工数量"
            size="mini"
            filterable
          >
            <el-option
              v-for="item in selectConfig.searchMemberList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            @change="companyTypeChanged"
            v-model="selectConfig.searchRegister"
            placeholder="注册状态"
            size="mini"
            filterable
          >
            <el-option
              v-for="item in selectConfig.searchRegisterList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-select
            @change="companyTypeChanged"
            v-model="selectConfig.searchCert"
            placeholder="认证状态"
            size="mini"
            filterable
          >
            <el-option
              v-for="item in selectConfig.searchCertList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-select
            @change="companyTypeChanged"
            v-model="selectConfig.searchSpec"
            placeholder="入规状态"
            size="mini"
            filterable
          >
            <el-option
              v-for="item in selectConfig.searchSpecList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          size="mini"
          style="width:170px"
          placeholder="请输入搜索关键字"
          @change="companyTypeChanged"
          clearable
          v-model="key"
        >
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-card>
      <!-- 表格内容 -->
      <div class="table-content" v-loading="loading">
        <el-card
          class="box-card table-content-item"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div class="company-left-message">
            <el-carousel
              v-if="getValue(item.aptitude.stat, 'photo').length > 0"
              :interval="3000"
              :arrow="
                getValue(item.aptitude.stat, 'photo').length === 0
                  ? 'never'
                  : 'always'
              "
              style="width:200px"
              height="200px"
              indicator-position="none"
            >
              <el-carousel-item
                v-for="(img, _index) in getValue(item.aptitude.stat, 'photo')"
                :key="_index"
              >
                <img :src="`${IMG_URL_PRE}${img}`" alt="缩略图" />
              </el-carousel-item>
            </el-carousel>
            <img v-else :src="defaultImg" alt="" style="width:200px" />
            <!-- 公司基本信息 -->
            <div class="company-base-message">
              <div class="first-line-message">
                <span class="company-name">{{ item.name }}</span>
                <el-tag type="success" v-if="item.reg_stat === 1"
                  >已注册</el-tag
                >
                <el-tag type="danger" v-else>未注册</el-tag>
                <el-tag type="success" v-if="item.cert_stat === 1"
                  >已认证</el-tag
                >
                <el-tag type="danger" v-else>未认证</el-tag>
              </div>
              <div>
                所在地:
                <span>{{ getValue(item.aptitude.stat, 'zone_str') }}</span>
              </div>
              <div>
                员工数量:
                <span>{{
                  getValue(item.aptitude.stat, 'n_employee_str')
                }}</span>
              </div>
              <div>
                总产值:
                <span>{{ getValue(item.aptitude.stat, 'amount') }}</span>
              </div>
              <div>
                工厂面积:
                <span>{{ getValue(item.aptitude.stat, 'area') }}</span>
              </div>
            </div>
          </div>

          <!-- 公司详细信息 -->
          <div class="company-right-message">
            <div class="company-right-message-first">
              <el-tag>{{ enumSpec[item.spec_type] }}</el-tag>
            </div>
            <div class="company-right-message-second">
              <!-- <div
                class="company-right-message-detail company-right-message-detail-electricity"
              >
                <div>
                  上月用电量： <span>{{ item.energyUsed }}</span> 度
                </div>
                <div>
                  上月用电量： <span>{{ item.energyUsed }}</span> 度
                </div>
              </div> -->
              <div
                class="company-right-message-detail company-right-message-detail-product"
              >
                <div class="product-number">{{ item.n_product }}</div>
                <div>已上传产品数</div>
              </div>
              <div
                class="company-right-message-detail company-right-message-detail-qualification"
              >
                <div class="qualification-number">
                  <el-progress
                    type="circle"
                    :percentage="item.aptitude_score"
                    :width="50"
                    :stroke-width="3"
                  ></el-progress>
                </div>
                <div>资质完善率</div>
              </div>
            </div>
            <!-- <div class="company-right-message-third"> 
              <span>最后更新时间：{{ item.lastUpdateTime }}</span>
            </div> -->
          </div>
        </el-card>
        <el-pagination
          style="background:#fff;padding:10px 0;margin-top:16px"
          background
          layout="prev, pager, next"
          @current-change="companyTypeChanged(true)"
          :current-page.sync="pageConfig.page"
          :page-size="pageConfig.size"
          :total="pageConfig.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getFactoryZones } from '@/service/dictionary'
import { getFactoryList } from '@/service/bureau'
import { IMG_URL_PRE } from '@/config'
import _ from 'lodash';


export default {
  components: {},
  data() {
    return {
      IMG_URL_PRE,
      defaultImg: require('@/assets/images/bureau/defaultImg.jpeg'),//列表默认图片
      loading: true,
      pageConfig: {
        page: 1,
        size: 20,
        total: 20,
      },
      enumSpec: {
        0: '未入规',
        1: '区规',
        2: '省规',
      },
      //下拉框配置
      selectConfig: {
        searchAddress: '',
        searchAddressList: [],
        searchMember: '',
        searchMemberList: [
          { id:'',name:'全部员工数量'},
          { id: 1, name: '1-20人' },
          { id: 2, name: '21-50人' },
          { id: 3, name: '51-100人' },
          { id: 4, name: '101-200人' },
          { id: 5, name: '201-500人' },
          { id: 6, name: '501-1000人' },
          { id: 7, name: '1001人以上' },
        ],
        searchRegister: '',
        searchRegisterList: [
          { id: 0, name: '全部' },
          { id: 1, name: '已注册' },
          { id: 2, name: '未注册' },
        ],
        searchCert: '',
        searchCertList: [
          { id: 0, name: '全部' },
          { id: 1, name: '已认证' },
          { id: 2, name: '未认证' },
        ],
        searchSpec: '',
        searchSpecList: [
          { id: 0, name: '全部' },
          { id: 1, name: '已入规' },
          { id: 2, name: '未入规' },
        ],
      },
      key: '',
      tableData: [],
    }
  },
  created() {},
  mounted() {
    this.init()
    let a = {
      b: {
        c: 'name'
      }
    }
    let res = _.get(a, 'a.b.c')
    console.log(res)
  },
  methods: {
    /**
     * @desc 获取深度对象的值
     * @param target:obj -目标对象
     * @param key:string -对象值
     */
    getValue(target, key) {
      // const ifDefine = target && target[key]
      const enumUnit = {
        zone_str:'',
        amount:' 万',
        n_employee_str:'',
        area:' m²',
      }
      if (key === 'photo') {
        return  _.get(target, key) || []
      }
      if(_.get(target, key) || _.get(target, key)===0){
        return `${_.get(target, key)} ${enumUnit[key]}`
      } else{
        return '未知'
      }


      // if (key === 'amount') {
      //   return _.get(target, key)?_.get(target, key)+' 万':'未知'
      //   // return (ifDefine && target[key] + ' 万') || '未知'
      // }
      // if (key === 'n_employee_str') {
      //   return _.get(target, key)?_.get(target, key)+' 人':'未知'
      //   // return (ifDefine && target[key] + ' 人') || '未知'
      // }
      // if (key === 'area_str') {
      //   return _.get(target, key)?_.get(target, key)+' m²':'未知'
      //   // return (ifDefine && target[key] + ' m²') || '未知'
      // }
    },
    //页面初始化
    init() {
      //工厂区域下拉框初始化
      this.getFactoryZonesInit()
      //渲染工厂表格
      this.renderFurnitureFactory()
    },
    //获取区域数据并渲染
    getFactoryZonesInit() {
      getFactoryZones().then((data) => {
        this.selectConfig.searchAddressList = [{id:'',name:'全部地区'}].concat(data)
      })
    },
    //渲染家具工厂表格数据
    renderFurnitureFactory() {
      this.tableData = [];
      this.loading = true;
      this.getFurnitureFactory().then((data) => {
        console.log(data.list, data.list[0])
        this.tableData = data.list
        this.pageConfig.total = data.total
        this.loading = false
      })
    },
    /**
     * @desc 获取家具工厂表格数据
     * @return res:[]object
     */
    async getFurnitureFactory() {
      let res = []
      const param = {
        zone_id: this.selectConfig.searchAddress, //zone_id
        n_employee: this.selectConfig.searchMember, //员工数量ID
        reg_stat: this.selectConfig.searchRegister, //注册状态，1已注册，2未注册，0全部
        cert_stat: this.selectConfig.searchCert, //认证状态，1已认证，2未认证，0全部
        spec_stat: this.selectConfig.searchSpec, //入规状态，1已入规，2未入规，0全部
        k: this.key, //搜索关键字
        page: this.pageConfig.page,
        pagesize: this.pageConfig.size,
      }
      await getFactoryList(param).then((data) => {
        res = data
      })
      return res

      // const item = {
      //   img: [
      //     require('@/assets/images/user.png'),
      //     require('@/assets/images/user.png'),
      //     require('@/assets/images/user.png'),
      //   ],
      //   companyName: '江西团团圆家具有限公司',
      //   isRegister: Math.floor(Math.random() * 2), //是否已注册
      //   isCertification: Math.floor(Math.random() * 2), //是否已认证
      //   address: '东山街道',
      //   numberOfWorkers: [21, 50],
      //   outputValue: 1024,
      //   area: 1300,
      //   tags: ['区规', '园区进区贷', '品牌联盟'],
      //   energyUsed: 2330,
      //   productNumber: 20,
      //   qualificationRate: 68,
      //   lastUpdateTime: '2019-10-22',
      // }
      // res = await new Array(10).fill(item)
      // return res
    },
    //搜索条件改变时触发的事件
    companyTypeChanged(type) {
      console.log("type",type);
      if (!type) {
        this.pageConfig.page = 1;
      }
      this.renderFurnitureFactory()
    },
  },
}
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.zt-page-content {
  > div {
    min-width: 1600px;
  }
}
.page-body {
  padding: 15px;
  .el-tag {
    line-height: 22px;
    height: 22px;
  }
  .box-card {
    ::v-deep .el-card__body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-event-item {
        display: flex;
        align-items: center;
        > div {
          & + div {
            margin-left: 56px;
          }
        }
      }
    }
  }
  .table-content {
    .table-content-item {
      margin-top: 16px;
      padding: 0px 36px 0px 36px;
      box-sizing: border-box;
      ::v-deep.el-card__body {
        justify-content: space-between;
        .company-left-message {
          height: 200px;
          display: flex;
          .el-carousel--horizontal {
            width: 200px;
            .el-carousel__item {
              // background-color: #99a9bf;
              img {
                width: 100%;
              }
            }
          }
          .company-base-message {
            margin-left: 56px;
            > div {
              margin-bottom: 8px;
              &:nth-child(1) {
                margin-bottom: 16px;
              }
              color: #4d4d4d;
              &.first-line-message {
                .company-name {
                  font-size: 16px;
                  font-weight: bold;
                }
                span + span {
                  margin-left: 16px;
                }
              }
              &:not(.first-line-message) {
                span {
                  color: #808080;
                }
              }
            }
          }
        }
        .company-right-message {
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .company-right-message-first {
            > span + span {
              margin-left: 16px;
            }
          }
          .company-right-message-second {
            display: flex;
            > div + div {
              margin-left: 32px;
            }
            .company-right-message-detail {
              width: 119px;
              height: 135px;
              background: rgba(245, 245, 245, 1);
              border-radius: 4px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: #808080;
              overflow: auto;
              > div {
                &:nth-child(1) {
                  margin-bottom: 15px;
                }
              }
              &.company-right-message-detail-electricity {
                width: 182px;
                span {
                  color: #e02020;
                }
              }
              &.company-right-message-detail-product {
                .product-number {
                  font-size: 32px;
                  color: #1890ff;
                }
              }
            }
          }
          .company-right-message-third {
            color: #cccccc;
          }
        }
      }
    }
  }
}
</style>
